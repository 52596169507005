import React, { useEffect, useState } from "react";
import { Container, Grid, Text } from "basis";
import { NavigationalLayout } from "../components";

import UserPostForm from "./UserPostForm";
import axiosHelper from "../../../core/axios-helper";
import { getMerchantID, getProfile } from "../../../core/auth";
import { partnerHubServiceBaseUrl } from "../../../core/config";
import { Flex, Spinner } from "@chakra-ui/react";
import { extractMerchantList } from "./utils/utils";
import { Loading } from "../../../components";


const Scene = () => {
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState();
  const [roleOptions, setRoleOptions] = useState([
    { value: 'order specialist', label: 'order specialist' },
    { value: 'corporate', label: 'Corporate' },
    { value: 'user', label: 'User' },
    { value: 'staff', label: 'Staff' },
])

  const merchantID = getMerchantID();
  const profile = getProfile();

  useEffect(async () => {
    async function fetchData() {
      // You can await here
      const url = `${partnerHubServiceBaseUrl}/merchant/hierarchy/${merchantID}`
      const response = await axiosHelper.withMerchantAuth.get(
        url
      )
      const result = extractMerchantList(response.data)
      setMerchants(result)

      if(profile?.isHopSop===true){
        setRoleOptions([
          { value: 'order specialist', label: 'order specialist' },
          { value: 'corporate', label: 'Corporate' },
          { value: 'user', label: 'User' }
      ])
      }else{
        setRoleOptions([
          { value: 'order specialist', label: 'order specialist' },
          { value: 'corporate', label: 'Corporate' },
          { value: 'user', label: 'User' },
          { value: 'staff', label: 'Staff' }
      ])
      }
    }
   
    fetchData();
    
  }, [])
  return (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={8}>

        <Grid.Item colSpan="all">
          <Text as="h3" textStyle="heading3">Add New User</Text>
        </Grid.Item>

        <Grid.Item colSpan="all">
          <Container bg="white" padding="8">
            {loading ? <Flex justifyContent="center" my={4}>
            <Loading />
            </Flex> :
              <UserPostForm  isHopSop={profile?.isHopSop} roleOptions={roleOptions} merchants={merchants}></UserPostForm>}
          </Container>
        </Grid.Item>

      </Grid>
    </NavigationalLayout>
  );
};

export default Scene;
