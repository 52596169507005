import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  Box,
  TagCloseButton,
  Tag,
  Checkbox,
  Grid,
  Switch,
  Text,
  useToast,
  Link,
  FormHelperText,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import { MultiSelect } from 'react-multi-select-component';
import axiosHelper from '../../../core/axios-helper';
import { navigate } from 'gatsby';
import { LoadingMessage } from '../../../components';
import { getMerchantID } from '../../../core/auth';
import styled from 'styled-components';
import { partnerHubServiceBaseUrl } from '../../../core/config';
import { getGMTTimeString } from './utils/utils';



const ThickBlackDivider = styled.hr`
  border: none;
  height: 2px; /* Adjust thickness as needed */
  background-color: black;
  width: 100%; /* Ensure it spans the full width */
  margin: 20px 0; /* Add margin for spacing */
`;

// const MerchantModalWrapper = ({
//     isOpen,
//     onClose,
//     merchants,
//     roleOptions,
//     setSelectedMerchantes,
//     setMerchantRoless,
//     initialValues
//   }) => {
//     const [selectedMerchants, setSelectedMerchants] = useState([]);
//     const [selectedRoles, setSelectedRoles] = useState([]);
//     const [merchantRoles, setMerchantRoles] = useState({});
//     const [selectAll, setSelectAll] = useState(false);
//     const finalFocusRef = useRef(null);
//     const [merchantStatus, setMerchantStatus] = useState({});
//     const [storeStatus, setStoreStatus] = useState({});
//     useEffect(() => {
//         if (initialValues && initialValues.selectedMerchants && initialValues.merchantRoles) {
//           const initialSelectedMerchants = initialValues.selectedMerchants.map((merchantId) => ({
//             label: merchants.find((m) => m.id === merchantId).name,
//             value: merchantId,
//           }));
//           setSelectedMerchants(initialSelectedMerchants);

//           setMerchantRoles(JSON.parse(JSON.stringify(initialValues.merchantRoles)));

//           const initialMerchantStatus = JSON.parse(JSON.stringify(initialValues.merchantStatus || {}));
//           setMerchantStatus(initialMerchantStatus);

//           const initialStoreStatus = JSON.parse(JSON.stringify(initialValues.storeStatus || {}));
//           setStoreStatus(initialStoreStatus);

//           const allRoles = [];
//           Object.values(initialValues.merchantRoles).forEach((roles) => {
//             roles.forEach((role) => {
//               if (!allRoles.includes(role)) {
//                 allRoles.push(role);
//               }
//             });
//           });
//           setSelectedRoles(roleOptions.filter((option) => allRoles.includes(option.value)));
//         } else {
//           setSelectedMerchants([]);
//           setSelectedRoles([]);
//           setMerchantRoles({});
//           setMerchantStatus({});
//           setStoreStatus({});
//           setSelectAll(false);
//         }
//       }, []);

//     const handleSelectAll = (isChecked) => {
//       setSelectAll(isChecked);
//       if (isChecked) {
//         setSelectedMerchants(merchants.map((merchant) => ({
//           label: merchant.name,
//           value: merchant.id,
//         })));
//         const newMerchantRoles = {};
//         merchants.forEach((merchant) => {
//           newMerchantRoles[merchant.id] = selectedRoles.map((role) => role.value);
//         });
//         setMerchantRoles(newMerchantRoles);
//         const newMerchantStatus = {};
//         merchants.forEach((merchant) => {
//           newMerchantStatus[merchant.id] = true;
//         });
//         setMerchantStatus(newMerchantStatus);
//       } else {
//         setSelectedMerchants([]);
//         setMerchantRoles({});
//         setMerchantStatus({});
//         setStoreStatus({});
//       }
//     };

//     const handleMerchantToggle = (merchantId) => {
//       const merchant = merchants.find((m) => m.id === merchantId);
//       if (selectedMerchants.some((m) => m.value === merchantId)) {
//         setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
//         setMerchantRoles((prevRoles) => {
//           const newRoles = { ...prevRoles };
//           delete newRoles[merchantId];
//           return newRoles;
//         });
//         setMerchantStatus((prevStatus) => {
//           const newStatus = { ...prevStatus };
//           delete newStatus[merchantId];
//           return newStatus;
//         });
//       } else {
//         setSelectedMerchants([...selectedMerchants, { label: merchant.name, value: merchantId }]);
//         setMerchantRoles((prevRoles) => ({
//           ...prevRoles,
//           [merchantId]: selectedRoles.map((role) => role.value),
//         }));
//       }
//     };

//     const handleRoleChange = (selected) => {
//       setSelectedRoles(selected);
//       if (selectAll) {
//         const newMerchantRoles = {};
//         merchants.forEach((merchant) => {
//           newMerchantRoles[merchant.id] = selected.map((role) => role.value);
//         });
//         setMerchantRoles(newMerchantRoles);
//       } else {
//         const updatedMerchantRoles = { ...merchantRoles };
//         selectedMerchants.forEach((merchant) => {
//           updatedMerchantRoles[merchant.value] = selected.map((role) => role.value);
//         });
//         setMerchantRoles(updatedMerchantRoles);
//       }
//     };

//     const handleRoleTagClose = (merchantId, roleToRemove) => {
//       setMerchantRoles((prevRoles) => {
//         const updatedRoles = {
//           ...prevRoles,
//           [merchantId]: prevRoles[merchantId].filter((role) => role !== roleToRemove),
//         };

//         if (updatedRoles[merchantId].length === 0) {
//           setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
//           setMerchantStatus((prevStatus) => {
//             const newStatus = { ...prevStatus };
//             delete newStatus[merchantId];
//             return newStatus;
//           });
//         }

//         return updatedRoles;
//       });
//     };

//     const handleSubmit = () => {
//       let hasEmptyRoles = false;

//       selectedMerchants.forEach((merchant) => {
//         if (!merchantRoles[merchant.value] || merchantRoles[merchant.value].length === 0) {
//           hasEmptyRoles = true;
//         }
//       });
//       function hasAnyMerchantEmptyRoles(selectedMerchants, merchantRoles) {
//         for (const merchant of selectedMerchants) {
//           const merchantId = merchant.value; // Get the merchant ID

//           if (merchantId && (!merchantRoles[merchantId] || merchantRoles[merchantId].length === 0)) {
//             return true; // Found a merchant with empty or null roles
//           }
//         }
//         return false; // No merchant has empty or null roles
//       }
//       const allRolesValid = hasAnyMerchantEmptyRoles(selectedMerchants, merchantRoles);
//       if (allRolesValid) {
//         alert('Please assign at least one role to each selected merchant.');
//         return;
//       }
//       const activeMerchantRoles = {};
//       selectedMerchants.forEach((merchant) => {
//         activeMerchantRoles[merchant.value] = merchantRoles[merchant.value];
//       });

//       setSelectedMerchantes(selectedMerchants.map((merchant) => merchant.value));
//       setMerchantRoless(activeMerchantRoles);
//       onClose();
//     };

//     useEffect(() => {
//       if (selectedMerchants.length === merchants.length) {
//         setSelectAll(true);
//       } else {
//         setSelectAll(false);
//       }
//     }, [selectedMerchants, merchants]);

//     const handleStatusToggle = (merchantId) => {
//       setMerchantStatus((prevStatus) => ({
//         ...prevStatus,
//         [merchantId]: !prevStatus[merchantId],
//       }));
//     };

//     const handleStoreStatusChange = (merchantId, status) => {
//       setStoreStatus((prevStatus) => ({
//         ...prevStatus,
//         [merchantId]: status,
//       }));
//     };

//     return (
//       <Modal isOpen={isOpen} onClose={onClose} size="xl" finalFocusRef={finalFocusRef} closeOnOverlayClick={false}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Select Merchants and Roles</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <Stack spacing={3}>
//               <Checkbox isChecked={selectAll} onChange={(e) => handleSelectAll(e.target.checked)}>
//                 Select All
//               </Checkbox>

//               <FormControl>
//                 <MultiSelect
//                   options={roleOptions.map((option) => ({ label: option.label, value: option.value }))}
//                   value={selectedRoles}
//                   onChange={handleRoleChange}
//                   labelledBy="Select Roles"
//                 />
//               </FormControl>

//               {/* <FormControl>
//                 <Select
//                   placeholder="Select Store Status"
//                   onChange={(e) => {
//                     const newStoreStatus = {};
//                     selectedMerchants.forEach((merchant) => {
//                       newStoreStatus[merchant.value] = e.target.value;
//                     });
//                     setStoreStatus(newStoreStatus);
//                   }}
//                 >
//                   <option value="yes">Yes</option>
//                   <option value="no">No</option>
//                 </Select>
//               </FormControl> */}

//               {merchants.map((merchant) => (
//                 <Box key={merchant.id}>
//                   <Checkbox
//                     isChecked={selectedMerchants.some((m) => m.value === merchant.id)}
//                     onChange={() => handleMerchantToggle(merchant.id)}
//                   >
//                     {merchant.name}
//                   </Checkbox>
//                   <Box ml={6} mt={2} display="flex" alignItems="center" flexWrap="wrap">
//                     {merchantRoles[merchant.id] &&
//                       merchantRoles[merchant.id].map((role) => (
//                         <Tag
//                           key={role}
//                           mr={2}
//                           mb={2}
//                           size="sm"
//                           colorScheme="gray"
//                           borderRadius="full"
//                         >
//                           {role}
//                         <TagCloseButton onClick={() => handleRoleTagClose(merchant.id, role)} />
//                       </Tag>
//                     ))}
//                   {/* {selectedMerchants.some((m) => m.value === merchant.id) && storeStatus[merchant.id] === "yes" && (
//                     <Switch
//                       size="md"
//                       isChecked={merchantStatus[merchant.id]}
//                       onChange={() => handleStatusToggle(merchant.id)}
//                       ml={2}
//                     />
//                   )} */}
//                 </Box>
//               </Box>
//             ))}
//           </Stack>
//         </ModalBody>
//         <ModalFooter>
//           <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
//             `Add  {selectedMerchants.length}
//           </Button>
//           <Button onClick={onClose} ref={finalFocusRef}>Cancel</Button>
//         </ModalFooter>
//       </ModalContent>
//     </Modal>
//   );
// };

// const MerchantModalWrapper = ({
//   isOpen,
//   onClose,
//   merchants,
//   roleOptions,
//   setSelectedMerchantes,
//   setMerchantRoless,
//   initialValues,
// }) => {
//   const [selectedMerchants, setSelectedMerchants] = useState([]);
//   const [merchantRoles, setMerchantRoles] = useState({});
//   const [selectAll, setSelectAll] = useState(false);
//   const finalFocusRef = useRef(null);

//   useEffect(() => {
//     if (initialValues && initialValues.selectedMerchants && initialValues.merchantRoles) {
//       const initialSelectedMerchants = initialValues.selectedMerchants.map((merchantId) => ({
//         label: merchants.find((m) => m.id === merchantId).name,
//         value: merchantId,
//       }));
//       setSelectedMerchants(initialSelectedMerchants);
//       setMerchantRoles(JSON.parse(JSON.stringify(initialValues.merchantRoles)));
//     } else {
//       setSelectedMerchants([]);
//       setMerchantRoles({});
//       setSelectAll(false);
//     }
//   }, [initialValues, merchants]);

//   const handleSelectAll = (isChecked) => {
//     setSelectAll(isChecked);
//     if (isChecked) {
//       setSelectedMerchants(merchants.map((merchant) => ({ label: merchant.name, value: merchant.id })));
//       const newMerchantRoles = {};
//       merchants.forEach((merchant) => {
//         newMerchantRoles[merchant.id] = roleOptions.map((role) => role.value);
//       });
//       setMerchantRoles(newMerchantRoles);
//     } else {
//       setSelectedMerchants([]);
//       setMerchantRoles({});
//     }
//   };

//   const handleMerchantToggle = (merchantId) => {
//     const merchant = merchants.find((m) => m.id === merchantId);
//     if (selectedMerchants.some((m) => m.value === merchantId)) {
//       setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
//       setMerchantRoles((prevRoles) => {
//         const newRoles = { ...prevRoles };
//         delete newRoles[merchantId];
//         return newRoles;
//       });
//     } else {
//       setSelectedMerchants([...selectedMerchants, { label: merchant.name, value: merchantId }]);
//       setMerchantRoles((prevRoles) => ({
//         ...prevRoles,
//         [merchantId]: roleOptions.map((role) => role.value),
//       }));
//     }
//   };

//   const handleRoleChange = (selected, merchantId) => {
//     setMerchantRoles((prevRoles) => ({
//       ...prevRoles,
//       [merchantId]: selected.map((role) => role.value),
//     }));
//   };

//   const handleRoleTagClose = (merchantId, roleToRemove) => {
//     setMerchantRoles((prevRoles) => {
//       const updatedRoles = {
//         ...prevRoles,
//         [merchantId]: prevRoles[merchantId].filter((role) => role !== roleToRemove),
//       };
//       if (updatedRoles[merchantId].length === 0) {
//         setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
//       }
//       return updatedRoles;
//     });
//   };

//   const handleSubmit = () => {
//     let hasEmptyRoles = false;
//     selectedMerchants.forEach((merchant) => {
//       if (!merchantRoles[merchant.value] || merchantRoles[merchant.value].length === 0) {
//         hasEmptyRoles = true;
//       }
//     });

//     if (hasEmptyRoles) {
//       alert('Please assign at least one role to each selected merchant.');
//       return;
//     }

//     const activeMerchantRoles = {};
//     selectedMerchants.forEach((merchant) => {
//       activeMerchantRoles[merchant.value] = merchantRoles[merchant.value];
//     });

//     setSelectedMerchantes(selectedMerchants.map((merchant) => merchant.value));
//     setMerchantRoless(activeMerchantRoles);
//     onClose();
//   };

//   useEffect(() => {
//     if (selectedMerchants.length === merchants.length) {
//       setSelectAll(true);
//     } else {
//       setSelectAll(false);
//     }
//   }, [selectedMerchants, merchants]);

//   return (
//     <Modal isOpen={isOpen} onClose={onClose} size="xl" finalFocusRef={finalFocusRef} closeOnOverlayClick={false}>
//       <ModalOverlay />
//       <ModalContent>
//         <ModalHeader>Select Merchants and Roles</ModalHeader>
//         <ModalCloseButton />
//         <ModalBody>
//           <Stack spacing={3}>
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//     <Checkbox
//         isChecked={selectAll}
//         onChange={(e) => handleSelectAll(e.target.checked)}
//     />
//     <span style={{ userSelect: 'none', marginLeft: '8px' }}>Select All</span>
// </div>
//             {merchants.map((merchant) => (
//               <Box key={merchant.id}>
//                 <Checkbox
//                   isChecked={selectedMerchants.some((m) => m.value === merchant.id)}
//                   onChange={() => handleMerchantToggle(merchant.id)}
//                 >
//                   {merchant.name}
//                 </Checkbox>
//                 <Box ml={6} mt={2}>
//                   <FormControl>
//                     <MultiSelect
//                       options={roleOptions.map((option) => ({ label: option.label, value: option.value }))}
//                       value={(merchantRoles[merchant.id] || []).map((role) => ({
//                         label: roleOptions.find((opt) => opt.value === role)?.label || role,
//                         value: role,
//                       }))}
//                       onChange={(selected) => handleRoleChange(selected, merchant.id)}
//                       labelledBy="Select Roles"
//                     />
//                   </FormControl>
//                   <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
//                     {(merchantRoles[merchant.id] || []).map((role) => (
//                       <Tag key={role} mr={2} mb={2} size="sm" colorScheme="gray" borderRadius="full">
//                         {role}
//                         <TagCloseButton onClick={() => handleRoleTagClose(merchant.id, role)} />
//                       </Tag>
//                     ))}
//                   </Box>
//                 </Box>
//               </Box>
//             ))}
//           </Stack>
//         </ModalBody>
//         <ModalFooter>
//           <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
//             {`Add ${selectedMerchants.length}`}
//           </Button>
//           <Button onClick={onClose} ref={finalFocusRef}>
//             Cancel
//           </Button>
//         </ModalFooter>
//       </ModalContent>
//     </Modal>
//   );
// };
const MerchantModalWrapper = ({
  isOpen,
  onClose,
  merchants,
  roleOptions,
  setSelectedMerchantes,
  setMerchantRoless,
  selectedMerchants: propSelectedMerchants,
  merchantRoles: propMerchantRoles,
}) => {
  const [selectedMerchants, setSelectedMerchants] = useState(propSelectedMerchants);
  const [merchantRoles, setMerchantRoles] = useState(propMerchantRoles);
  const [selectAll, setSelectAll] = useState(false);
  const finalFocusRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      // Populate local state with prop values
      const convertedSelectedMerchants = propSelectedMerchants.map((id) => ({
        label: merchants.find((m) => m.id === id)?.name || id, // Use merchant name or ID
        value: id,
      }));

      setSelectedMerchants(convertedSelectedMerchants);
      setMerchantRoles(propMerchantRoles);
      // Update selectAll based on propSelectedMerchants
      if (propSelectedMerchants.length === merchants.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    } else {
      // Reset local state when modal closes
      setSelectedMerchants([]);
      setMerchantRoles({});
      setSelectAll(false);
    }
  }, [isOpen, propSelectedMerchants, propMerchantRoles, merchants]);

  // const handleSelectAll = (isChecked) => {
  //   setSelectAll(isChecked);
  //   if (isChecked) {
  //     setSelectedMerchants(merchants.map((merchant) => ({ label: merchant.name, value: merchant.id })));
  //     const newMerchantRoles = {};
  //     merchants.forEach((merchant) => {
  //       newMerchantRoles[merchant.id] = roleOptions.map((role) => role.value);
  //     });
  //     setMerchantRoles(newMerchantRoles);
  //   } else {
  //     setSelectedMerchants([]);
  //     setMerchantRoles({});
  //   }
  // };
  const handleSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    if (isChecked) {
      const allMerchantIds = merchants.map((merchant) => ({
        label: merchant.name, // Or any label you want to use
        value: merchant.id,
      }));

      setSelectedMerchants(allMerchantIds);

      setMerchantRoles((prevRoles) => {
        const newMerchantRoles = { ...prevRoles };
        allMerchantIds.forEach((merchant) => {
          const merchantId = merchant.value;
          if (!prevRoles[merchantId]) {
            // Only add all roles if the merchant didn't have roles before
            newMerchantRoles[merchantId] = ['user'];
          }
        });
        return newMerchantRoles;
      });
    } else {
      setSelectedMerchants([]);
      setMerchantRoles({});

    }
  };

  // const handleMerchantToggle = (merchantId) => {
  //   const merchant = merchants.find((m) => m.id === merchantId);
  //   if (selectedMerchants.some((m) => m.value === merchantId)) {
  //     setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
  //     setMerchantRoles((prevRoles) => {
  //       const newRoles = { ...prevRoles };
  //       delete newRoles[merchantId];
  //       return newRoles;
  //     });
  //   } else {
  //     setSelectedMerchants([...selectedMerchants, { id: merchant.name, value: merchantId }]);
  //     setMerchantRoles((prevRoles) => ({
  //       ...prevRoles,
  //       [merchantId]: roleOptions.map((role) => role.value),
  //     }));
  //   }
  //    // Update selectAll after toggling a merchant
  // if (selectedMerchants.length === merchants.length) {
  //   setSelectAll(true);
  // } else {
  //   setSelectAll(false);
  // }
  // };
  const handleMerchantToggle = (merchantId) => {
    const merchant = merchants.find((m) => m.id === merchantId);

    if (selectedMerchants.some((m) => m.value === merchantId)) {
      setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
    } else {
      setSelectedMerchants([...selectedMerchants, {
        label: merchant.name,
        value: merchant.id,
      }]);

      setMerchantRoles((prevRoles) => {
        if (prevRoles[merchantId]) {
          return prevRoles;
        } else {
          return {
            ...prevRoles,
            [merchantId]: ['user'],
          };
        }
      });
    }

    if (selectedMerchants.length === merchants.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  // const handleMerchantToggle = (merchantId) => {
  //   const merchant = merchants.find((m) => m.id === merchantId);

  //   if (selectedMerchants.some((m) => m.value === merchantId)) {
  //     // Merchant is being deselected
  //     setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));

  //     setMerchantRoles((prevRoles) => {
  //       const newRoles = { ...prevRoles };
  //       delete newRoles[merchantId];
  //       return newRoles;
  //     });
  //   } else {
  //     // Merchant is being selected
  //     setSelectedMerchants([...selectedMerchants, { label: merchant.name, value: merchantId }]);

  //     // Only set roles if they don't exist, otherwise preserve them
  //     setMerchantRoles((prevRoles) => {
  //       if (prevRoles[merchantId]) {
  //         return prevRoles; // Preserve existing roles
  //       } else {
  //         return {
  //           ...prevRoles,
  //           [merchantId]: roleOptions.map((role) => role.value), // Set all roles initially
  //         };
  //       }
  //     });
  //   }

  //   // Update selectAll after toggling a merchant
  //   if (selectedMerchants.length === merchants.length) {
  //     setSelectAll(true);
  //   } else {
  //     setSelectAll(false);
  //   }
  // };
  const handleRoleChange = (selected, merchantId) => {
    setMerchantRoles((prevRoles) => ({
      ...prevRoles,
      [merchantId]: selected.map((role) => role.value),
    }));
  };

  const handleRoleTagClose = (merchantId, roleToRemove) => {
    setMerchantRoles((prevRoles) => {
      const updatedRoles = {
        ...prevRoles,
        [merchantId]: prevRoles[merchantId].filter((role) => role !== roleToRemove),
      };
      if (updatedRoles[merchantId].length === 0) {
        setSelectedMerchants(selectedMerchants.filter((m) => m.value !== merchantId));
      }
      return updatedRoles;
    });
  };

  const handleSubmit = () => {
    let hasEmptyRoles = false;
    selectedMerchants.forEach((merchant) => {
      if (!merchantRoles[merchant.value] || merchantRoles[merchant.value].length === 0) {
        hasEmptyRoles = true;
      }
    });

    if (hasEmptyRoles) {
      alert('Please assign at least one role to each selected merchant.');
      return;
    }

    const activeMerchantRoles = {};
    selectedMerchants.forEach((merchant) => {
      activeMerchantRoles[merchant.value] = merchantRoles[merchant.value];
    });

    setSelectedMerchantes(selectedMerchants.map((merchant) => merchant.value));
    setMerchantRoless(activeMerchantRoles);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" finalFocusRef={finalFocusRef} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Merchants and Roles</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px' }}>
              <Checkbox
                sz={'lg'}
                isChecked={selectAll}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
              <span style={{ userSelect: 'none', marginLeft: '8px' }}>Select All - activates and assigns the default “User” role to all stores</span>
            </div>
            {merchants.map((merchant) => (
              <Box key={merchant.id}>
                <Checkbox
                  isChecked={selectedMerchants.some((m) => m.value === merchant.id)}
                  onChange={() => handleMerchantToggle(merchant.id)}
                >
                  {merchant.name}
                </Checkbox>
                <Box ml={6} mt={2}>
                  <FormControl>
                    <MultiSelect
                      options={roleOptions.map((option) => ({ label: option.label, value: option.value }))}
                      value={(merchantRoles[merchant.id] || []).map((role) => ({
                        label: roleOptions.find((opt) => opt.value === role)?.label || role,
                        value: role,
                      }))}
                      onChange={(selected) => handleRoleChange(selected, merchant.id)}
                      labelledBy="Select Roles"
                    />
                  </FormControl>
                  <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                    {(merchantRoles[merchant.id] || []).map((role) => (
                      <Tag key={role} mr={2} mb={2} size="sm" colorScheme="gray" borderRadius="full">
                        {role}
                        <TagCloseButton onClick={() => handleRoleTagClose(merchant.id, role)} />
                      </Tag>
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {`Add ${selectedMerchants.length}`}
          </Button>
          <Button onClick={onClose} ref={finalFocusRef}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};


export default function UserPostForm({merchants, roleOptions, isHopSop }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMerchants, setSelectedMerchantes] = useState([]);
  const [merchantRoles, setMerchantRoless] = useState({});
  const [useDefaultStoreAccess, setUseDefaultStoreAccess] = useState(true);
  const [merchantTraining, setMerchantTraining] = useState(false);
  const [userName, setUserName] = useState(''); // Added username state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameErrorText, setFirstNameErrorText] = useState('')
  const [lastNameErrorText, setLastNameErrorText] = useState('')
  const [userNameError, setUserNameError] = useState();
  const [emailError, setEmailError] = useState()
  const [customStoreAccess, setCustomStoreAccess] = useState('');
  const [adminChecked, setAdminChecked] = useState(false);
  const [userChecked, setUserChecked] = useState(false);
  const [corporateChecked, setCorporateChecked] = useState(false);
  const [staffChecked, setStaffChecked] = useState(false);
  const [loading, setLoading] = useState()
  const toast = useToast();
  const merchantID = getMerchantID();
  const initialValues = {}
  useEffect(() => {
    if (initialValues) {
      setUserName(initialValues.userName || '');
      setFirstName(initialValues.firstName || '');
      setLastName(initialValues.lastName || '');
      setEmail(initialValues.email || '');
      setSelectedMerchantes(initialValues.selectedMerchants ? [...initialValues.selectedMerchants] : []);
      setMerchantRoless(initialValues.merchantRoles ? JSON.parse(JSON.stringify(initialValues.merchantRoles)) : {});
    } else {
      setUserName('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setSelectedMerchantes([]);
      setMerchantRoless({});
    }
  }, []);
  const handleStoreAccessToggle = () => {
    setUseDefaultStoreAccess(!useDefaultStoreAccess);
  };
  const handleTrainingCompleted = () => {
    setMerchantTraining(!merchantTraining);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const roleOptions = [
  //     { value: 'order specialist', label: 'order specialist' },
  //     { value: 'corporate', label: 'Corporate' },
  //     { value: 'user', label: 'User' },
  //     { value: 'staff', label: 'Staff' },
  // ];
  const storeStatus = [
    { value: 'active', label: 'Yes' },
    { value: 'inactive', label: 'No' },
  ]

  const handleRoleTagClose = (merchantId, roleToRemove) => {
    setMerchantRoless((prevRoles) => {
      const updatedRoles = { ...prevRoles };
      if (updatedRoles[merchantId]) {
        updatedRoles[merchantId] = updatedRoles[merchantId].filter((role) => role !== roleToRemove);
        if (updatedRoles[merchantId].length === 0) {
          const newSelectedMerchants = selectedMerchants.filter((id) => id !== merchantId);
          setSelectedMerchantes(newSelectedMerchants);
          delete updatedRoles[merchantId];
        }
      }
      return updatedRoles;
    });
  };
  const transformMerchantData = (merchantRoles) => {
    const currentDateGMT = new Date().toUTCString();
    const merchantsArray = Object.entries(merchantRoles).map(([merchantName, roles]) => {
      return {
        createdAt: currentDateGMT,
        isActive: true,
        merchantId: merchantName,
        userRoles: roles,
      };
    });

    return merchantsArray;
  };
  const handleUserNameChange = (e) => {
    const newUserName = e.target.value;
    setUserName(newUserName);
    if (!email) {
      setEmail(e.target.value);
    }
    setUserNameError(false)
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };
  const submitForm = async () => {
    const isTextFieldsEmpty = firstName === '' && lastName === '' && email === '';
    if (firstName.length < 1) {
      setFirstNameError(true)
    }
    if (firstName.length > 32) {
      setFirstNameErrorText(true)
    }
    if (lastName.length < 1) {
      setLastNameError(true)
    }
    if (lastName.length > 32) {
      setLastNameErrorText(true)
    }
    // if(!validateEmail(userName)){
    //   setUserNameError(true)
    // }
    if (!validateEmail(email)) {
      setEmailError(true)
      return;
    }
    if (firstName === '' || firstName.length > 32 || lastName === '' || lastName.length > 32) {
      return;
    }

    if (merchants.length === 1) {
      // Single merchant logic
      if (isTextFieldsEmpty && !adminChecked && !userChecked && !corporateChecked && !staffChecked) {
        toast({
          title: 'Missing Fields',
          description: 'Please fill all text fields and select at least one role.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }
      if (!isTextFieldsEmpty && !adminChecked && !userChecked && !corporateChecked && !staffChecked) {
        toast({
          title: 'Missing Fields',
          description: 'Please select at least one role.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }
      if (isTextFieldsEmpty && (adminChecked || userChecked || corporateChecked || staffChecked)) {
        toast({
          title: 'Missing Fields',
          description: 'Please fill all text fields.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }
    } else {
      // Multiple merchants logic
      const isMerchantRolesEmpty = Object.keys(merchantRoles).length === 0;
      const isMerchantSelectionEmpty = selectedMerchants.length === 0;

      if (isTextFieldsEmpty || isMerchantSelectionEmpty || isMerchantRolesEmpty) {
        let description = 'Please fill all the fields in and ensure at least one merchant and role is selected.';

        if (isTextFieldsEmpty && !isMerchantSelectionEmpty && !isMerchantRolesEmpty) {
          description = 'Please fill all the text fields.';
        } else if (!isTextFieldsEmpty && isMerchantSelectionEmpty && !isMerchantRolesEmpty) {
          description = 'Please select at least one merchant.';
        } else if (!isTextFieldsEmpty && !isMerchantSelectionEmpty && isMerchantRolesEmpty) {
          description = 'Please select at least one role.';
        } else if (isTextFieldsEmpty && isMerchantSelectionEmpty && isMerchantRolesEmpty) {
          description = 'Please fill all the text fields, and select at least one merchant and role.';
        }

        toast({
          title: 'Missing Fields',
          description: description,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }
    }
    const gmtTimeString = getGMTTimeString();
    const formData = {
      userName, // Added username to form data
      firstName,
      lastName,
      email,
      isActive: useDefaultStoreAccess,
      trainingCompletedAt: merchantTraining === true ? gmtTimeString : '',
    };
    if (selectedMerchants.length === 0) {
      const roles = [];
      if (corporateChecked) {
        roles.push("corporate")
      }
      if (userChecked) {
        roles.push("user")
      }
      if (staffChecked) {
        roles.push("staff")
      }
      if (adminChecked) {
        roles.push("order specialist")
      }
      formData.merchants = [
        {
          "createdAt": new Date().toUTCString(),
          "isActive": true,
          "merchantId": merchants[0].name,
          "userRoles": roles

        }
      ]

    } else {
      formData.merchants = transformMerchantData(merchantRoles)
    }
    setLoading(true)
    const response = await axiosHelper.withMerchantAuth.post(
      `${partnerHubServiceBaseUrl}/account`,
      JSON.stringify(formData)
    );
    if (response.status !== 200) {
      // logger.error("Error while fetching transactions", response.data);
      toast({
        title: 'Email already registered use different email id to create a new user',
        description: response.data,
        status: 'error',
        duration: 7000,
        isClosable: true,
        position: 'top-right',
      });
      setLoading(false)
      return;
    }
    setLoading(false)
    window.location.href = '/merchant/manageusers/dashboard'
  };
  return (
    <Box p={4} maxW="400px">
      <Stack spacing={6}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text color="blue.500" mr={2}>
            Active User:
          </Text>
          <Switch
            size="md"
            isChecked={useDefaultStoreAccess}
            onChange={handleStoreAccessToggle}
            mr={2}
          />
        </Box>
        {/* <FormControl id="userName">
        <FormLabel>User Name</FormLabel>
        <Input
          placeholder="User name"
          value={userName}
          onChange={handleUserNameChange}
          isInvalid={!validateEmail(userName) && userName || userNameError===true}
        />
        
        <FormHelperText>
        Email Format (ie. user@domain.com)
      </FormHelperText>
      {(!validateEmail(userName) && userName || userNameError===true) && <Text color="red.500" fontSize="sm">Please enter a valid user name.</Text>}
      </FormControl> */}

        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={(!validateEmail(email) && email || emailError === true)}
            errorBorderColor="red.500"
          />
          {(!validateEmail(email) && email || emailError === true) && <Text color="red.500" fontSize="sm">Please enter a valid email address.</Text>}
        </FormControl>

        <FormControl id="firstName">
          <FormLabel>First Name</FormLabel>
          <Input
            placeholder="First name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value)
              setFirstNameError(false)
              if (e.target.value.length > 32) {
                setFirstNameErrorText(true)
              } else {
                setFirstNameErrorText(false)
              }
            }}
            isInvalid={firstNameError === true || firstNameErrorText === true}
            errorBorderColor="red.500"
          />
          {firstNameError === true && <Text color="red.500" fontSize="sm">First name must have at least 1 character.</Text>}
          {firstNameErrorText === true && <Text color="red.500" fontSize="sm">First name must not exceed more than 32 characters.</Text>}
        </FormControl>

        <FormControl id="lastName">
          <FormLabel>Last Name</FormLabel>
          <Input
            placeholder="Last name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value)
              setLastNameError(false)
              if (e.target.value.length > 32) {
                setLastNameErrorText(true)
              } else {
                setLastNameErrorText(false)
              }
            }}
            isInvalid={lastNameError === true}
            errorBorderColor="red.500"
          />
          {lastNameError === true && <Text color="red.500" fontSize="sm">Last name must have at least 1 character.</Text>}
          {lastNameErrorText === true && <Text color="red.500" fontSize="sm">Last name must not exceed more than 32 characters.</Text>}

        </FormControl>


        {merchants.length === 1 ? (
          <FormControl id="storeAccess">
            <FormLabel>Store Access</FormLabel>
            <Input value={merchants[0].name} isReadOnly />
          </FormControl>
        ) : (
          <Link color="blue.500" onClick={openModal}>
            Select Multiple Merchants and Roles
          </Link>
        )}
        {selectedMerchants.length >= 1 && (
          <Box bg="blue.100" p={4} borderRadius="md">
            {selectedMerchants.map((merchantId) => (
              <Box key={merchantId} mb={2} display="flex" alignItems="center">
                <Box flex="1">
                  {merchants.find((m) => m.id === merchantId).name}:
                  <Box ml={2} display="flex" flexWrap="wrap">
                    {merchantRoles[merchantId] &&
                      merchantRoles[merchantId].map((role) => (
                        <Tag
                          key={role}
                          mr={2}
                          mb={2}
                          size="sm"
                          colorScheme="gray"
                          borderRadius="full"
                        >
                          {role}
                          <TagCloseButton onClick={() => handleRoleTagClose(merchantId, role)} />
                        </Tag>
                      ))}

                  </Box>
                  <ThickBlackDivider />
                </Box>
                {/* <Button
              size="sm"
              colorScheme="blue"
              ml={2}
              onClick={() => {
                const newSelectedMerchants = selectedMerchants.filter((id) => id !== merchantId);
                setSelectedMerchantes(newSelectedMerchants);
                setMerchantRoless((prevRoles) => {
                  const updatedRoles = { ...prevRoles };
                  delete updatedRoles[merchantId];
                  return updatedRoles;
                });
              }}
            >
              X
            </Button> */}
              </Box>
            ))}

          </Box>
        )}
        {merchants.length === 1 && (
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <Box bg="black.100" p={4} borderRadius="md">
              <Checkbox variant="solid" id="order specialist" value="order specialist" isChecked={adminChecked} onChange={(e) => setAdminChecked(e.target.checked)}>
                Order Specialist
              </Checkbox>
              <Text fontSize="sm" mt={2}>
                Ability to create and manage orders from Orders Dashboard.
              </Text>
            </Box>

            <Box bg="black.100" variant="solid" p={4} borderRadius="md">
              <Checkbox id="user" value="user" isChecked={userChecked} onChange={(e) => setUserChecked(e.target.checked)}>
                User
              </Checkbox>
              <Text fontSize="sm" mt={2}>
                Read-Only access to Orders Dashboard.
              </Text>
            </Box>

            <Box bg="black.100" p={4} borderRadius="md">
              <Checkbox id="corporate"  variant="solid" value="corporate" isChecked={corporateChecked} onChange={(e) => setCorporateChecked(e.target.checked)}>
                Corporate
              </Checkbox>
              <Text fontSize="sm" mt={2}>
                Access to Reports including Settlements.
              </Text>
            </Box>

            {isHopSop === false && (<Box bg="black.100" p={4} borderRadius="md">
              <Checkbox id="staff" variant="solid" value="staff" isChecked={staffChecked} onChange={(e) => setStaffChecked(e.target.checked)}>
                Staff
              </Checkbox>
              <Text fontSize="sm" mt={2}>
                Read-Only access to Orders Dashboard with ability to Create New Orders.
              </Text>
            </Box>)}
          </Grid>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text color="blue.500" mr={2}>
            Training Completed:
          </Text>
          <Switch
            size="md"
            isChecked={merchantTraining}
            onChange={handleTrainingCompleted}
            mr={2}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button colorScheme="blue" onClick={submitForm}>
            {loading ? <Spinner /> : 'Finish'}
          </Button>
          <Button onClick={() => window.location.href = '/merchant/manageusers/dashboard'}>
            Cancel
          </Button>
        </Box>
      </Stack>

      <MerchantModalWrapper
        selectedMerchants={selectedMerchants}
        storeStatus={storeStatus}
        setSelectedMerchantes={setSelectedMerchantes}
        merchantRoles={merchantRoles}
        setMerchantRoless={setMerchantRoless}
        merchants={merchants}
        roleOptions={roleOptions}
        isOpen={isModalOpen}
        onClose={closeModal}
        initialValues={initialValues}
      />
    </Box>
  );
}
